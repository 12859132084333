import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as Styled from '../../templates/locationTemplate/locationPageTemplateStyle';
import IconPinBranch from '../../images/icon-pin-retail_New.png';
import GoogleMapReact from 'google-map-react';
import { Row, Col } from 'react-bootstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet } from 'react-helmet';
import { isSmallDevice } from '../../utils';

let markersArray = [];
const LocationDefault = ({ location }) => {
  const inputTablet = useRef(null);
  useEffect(async () => {
    const hash = location?.hash?.replace('#', '');
    const waitedID = await new Promise((resolve) => {
      document?.getElementById(hash) && resolve(true);
    });
    if (waitedID) {
      setTimeout(() => {
        window.location.href = `#${hash}`;
      }, 500);
    }
  }, []);
  const locationDataQuery = useStaticQuery(graphql`
    query locationDataMyQueryDefault {
      allContentfulLocation(
        filter: {
          node_locale: { eq: "en-US" }
          brand: { brand: { eq: "Chevron" } }
          latitude: { ne: null }
          longitude: { ne: null }
        }
      ) {
        edges {
          node {
            address1
            address2
            node_locale
            branchHours
            branchManager
            cardlockHours
            email
            hasDef
            type {
              type
            }
            hasDiesel
            hasGasoline
            hasHeatingOil
            hasHeatingEquipment
            hasLubricants
            hasPropane
            hasLunchCounter
            hasMarkedDiesel
            hasMultiServices
            hasParking
            hasRestaurant
            hasShowers
            hasRestrooms
            hasWifi
            hasDriversLounge
            hasConvenienceStore
            latitude
            location
            longitude
            offerings {
              Services
            }
            phone
            tollfree
            pipelineCardAccepted
            postalCode
            serviceArea {
              serviceArea
            }
            testimonial {
              raw
            }
            notes {
              raw
            }
            city
            province
            id
            spaceId
            useLatLongOnly
            urlSlug
            fax
            metaDescription
          }
        }
      }
    }
  `);
  let phones;
  const [locationsData, setLocationsData] = useState(
    locationDataQuery?.allContentfulLocation?.edges
  );
  const [lat, setLat] = useState(parseFloat(locationsData[0]?.node?.latitude));
  const [lng, setLng] = useState(parseFloat(locationsData[0]?.node?.longitude));
  const [zoom, setZoom] = useState(5);
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [clickedLocation, setClickedLocation] = useState(false);
  let InfoWindowObject = null;

  const closeOtherInfo = () => {
    if (InfoWindowObject) {
      /* detach the info-window from the marker ... undocumented in the API docs */
      InfoWindowObject?.set('marker', null);
      /* and close it */
      InfoWindowObject?.close();
      /* blank the Object */
      InfoWindowObject = null;
    }
  };
  const getMap = (loc) => {
    setLat(parseFloat(loc?.latitude));
    setLng(parseFloat(loc?.longitude));
    setClickedLocation(loc);
    if (isSmallDevice()) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${loc?.location},${loc?.address1},${loc?.city},${loc?.province}, ${loc?.postalCode}`,
        '_blank'
      );
    }
  };

  const searchByCityNameOrPostalCode = (searchText) => {
    setClickedLocation({});
    let hasMultipleResults = false;
    const searchResult = locationsData?.find(
      (item) =>
        item?.node?.city?.trim().toLowerCase() ===
          searchText.trim().toLowerCase() ||
        item?.node?.postalCode?.trim().toLowerCase() ===
          searchText.trim().toLowerCase() ||
        item?.node?.location?.trim().toLowerCase() ===
          searchText.trim().toLowerCase()
    );
    const multipleResults = locationsData?.filter(
      (item) =>
        item?.node?.city?.trim().toLowerCase() ===
          searchText.trim().toLowerCase() ||
        item?.node?.postalCode?.trim().toLowerCase() ===
          searchText.trim().toLowerCase() ||
        item?.node?.location?.trim().toLowerCase() ===
          searchText.trim().toLowerCase()
    );
    if (multipleResults && multipleResults.length > 1) {
      hasMultipleResults = true;
    }
    if (searchResult) {
      setLat(parseFloat(searchResult?.node?.latitude));
      setLng(parseFloat(searchResult?.node?.longitude));
      hasMultipleResults ? setZoom(10) : setZoom(15);
    } else {
      var geocoder = new google.maps.Geocoder();
      geocoder?.geocode({ address: searchText }, function (results, status) {
        if (status === 'OK') {
          setLat(results[0]?.geometry?.location?.lat());
          setLng(results[0]?.geometry?.location?.lng());
          setZoom(10);
        }
      });
    }
  };

  const getMarkerIcon = (types) => {
    let markerIcon = null;
    markerIcon = IconPinBranch;
    return markerIcon;
  };

  useEffect(() => {
    let result = locationDataQuery?.allContentfulLocation?.edges;
    setLocationsData(result);
    setLat(parseFloat(result[0]?.node?.latitude));
    setLng(parseFloat(result[0]?.node?.longitude));
  }, []);

  useEffect(() => {
    //delete all markers
    if (markersArray?.length >= 1) {
      markersArray?.forEach((marker) => {
        marker?.setMap(null);
      });
      markersArray = [];
    }

    if (maps && map && markersArray.length < 1) {
      locationsData?.map((location, index) => {
        if (location?.node?.address1) {
          const marker = new maps.Marker({
            position: new maps.LatLng(
              location?.node?.latitude ?? 0,
              location?.node?.longitude ?? 0
            ),
            icon: {
              url: getMarkerIcon(location?.node?.type), // url
              scaledSize: new google.maps.Size(33, 48), // size
            },
            map: map,
          });

          markersArray?.push(marker);
          const infoWindow = new maps.InfoWindow({
            content: `
                          <div class="infoWindow">
                              <h2>${location?.node?.city} </h2>
                              <br/>
                              <div class="infoWindow-left">
                                ${location?.node?.address1}${`,`}
                                <br />
                                ${
                                  location?.node?.address2 !== null
                                    ? location?.node?.address2 + '<br/>'
                                    : ''
                                }
                                ${location?.node?.city}, ${
              location?.node?.province
            }, ${location?.node?.postalCode}
                                <div
                                  tabindex="0"
                                >
                                Phone : 
                                ${
                                  location?.node?.phone !== null
                                    ? location?.node?.phone
                                    : ''
                                }  
                                ${
                                  location?.node?.fax === null
                                    ? ''
                                    : `<br/>Fax:${' '}${location?.node?.fax}`
                                }
                                <br/>
                                <br/>
                                ${
                                  location?.node?.branchHours !== null
                                    ? location?.node?.branchHours
                                    : ''
                                }
                                  
                                </div>
                              <br/>
                              </div>
                            
                                
                          </div>`,
            ariaLabel: 'Marker',
          });
          if (
            clickedLocation &&
            clickedLocation.latitude === location.node.latitude &&
            clickedLocation.longitude === location.node.longitude
          ) {
            map.panTo(marker?.getPosition());
            closeOtherInfo();
            setZoom(14);
            InfoWindowObject = infoWindow;
            infoWindow?.open({
              anchor: marker,
              map,
            });
          }
          //marker.setMap(null);
          marker?.addListener('click', () => {
            closeOtherInfo();
            infoWindow?.open({
              anchor: marker,
              map,
            });
            map.panTo(marker?.getPosition());
            InfoWindowObject = infoWindow;
          });
        }
      });
    }
  }, [locationsData, map, maps, clickedLocation]);

  const SearchComponent = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Parkland | Chevron :: Locations</title>
        </Helmet>
        <Styled.MapSearch>
          <Styled.MapSearchSub>
            <Styled.MapSearchInput>
              <Styled.MapSearchInputField
                ref={inputTablet}
                type="text"
                placeholder="Search by City or Postal Code"
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    searchByCityNameOrPostalCode(event?.target?.value);
                  }
                }}
              />
            </Styled.MapSearchInput>
            <Styled.MapSearchSubmit>
              <Styled.MapSearchSubmitInput
                type="submit"
                value=""
                onClick={() => {
                  searchByCityNameOrPostalCode(inputTablet?.current?.value);
                }}
              />
            </Styled.MapSearchSubmit>
          </Styled.MapSearchSub>
        </Styled.MapSearch>
      </>
    );
  };

  const handleApiLoaded = (map, maps) => {
    const styles = [
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#98d0ff' }],
      },
    ];

    // eslint-disable-next-line no-undef
    const styledMap = new google.maps.StyledMapType(styles, {
      name: 'Styled Map',
    });
    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Parkland | Chevron :: Locations</title>
        <meta name="description" content></meta>
      </Helmet>
      <Styled.SpanStyle id="title"></Styled.SpanStyle>
      <Styled.LocationContainer>
        <Row className="g-0 d-none d-md-block">
          <Col style={{ position: 'relative', margin: 'auto' }} md={6}>
            <SearchComponent />
          </Col>
          <Styled.LocationDataCol>
            <Styled.GoogleMapContainer>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.GATSBY_GOOGLE_MAP_APIKEY,
                  libraries: ['places', 'geometry'],
                }}
                center={{ lat, lng }}
                options={{ streetView: true, streetViewControl: true }}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  setMap(map);
                  setMaps(maps);
                  handleApiLoaded(map, maps);
                }}
              />
            </Styled.GoogleMapContainer>
          </Styled.LocationDataCol>
        </Row>
        <Row style={{ paddingTop: '20px' }} className="g-0 " id="retail">
          <Styled.TableSubContainer xs={12} sm={12} md={12} lg>
            <Styled.LocationTable striped borderless>
              <Styled.THead>
                <tr>
                  <th style={{ width: '25%' }}>Location</th>
                  <th style={{ width: '25%' }}>Contact Info</th>
                  <th style={{ width: '25%' }}>Hours</th>
                  <th style={{ width: '25%' }}>Offering</th>
                </tr>
              </Styled.THead>
              <Styled.TBody>
                {locationsData
                  ?.sort((a, b) => {
                    if (a.node.city === b.node.city) {
                      return a.node.address1.localeCompare(b.node.address1);
                    } else {
                      return a.node.city.localeCompare(b.node.city);
                    }
                  })
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <Styled.Td>
                          <div className="d-flex flex-column ">
                            <Styled.LinkStyle
                              href="#title"
                              onClick={() => getMap(item?.node)}
                            >
                              <Styled.AddressText>
                                <div> {item.node.city}</div>
                                <div> {item.node.address1}</div>
                                <div>
                                  {item.node.city}, {item.node.province}
                                  &nbsp;&nbsp;&nbsp;
                                  {item.node.postalCode}
                                </div>
                              </Styled.AddressText>
                            </Styled.LinkStyle>
                            {item?.node?.notes && (
                              <Styled.PipelineStyle>
                                {documentToReactComponents(
                                  JSON.parse(item?.node?.notes?.raw)
                                )}
                              </Styled.PipelineStyle>
                            )}
                          </div>
                        </Styled.Td>
                        <Styled.Td>
                          {item.node.phone &&
                            (phones = item.node.phone.split(' ')) && (
                              <>
                                Phone:&nbsp;
                                {phones[0] !== '!' && <>{phones[0]}</>}
                                &nbsp;
                                {phones[1] && <>{phones[1]}</>}
                              </>
                            )}
                          {item?.node?.fax !== null && (
                            <>
                              <br />
                              Fax:&nbsp;{item?.node?.fax}
                            </>
                          )}
                          {item.node.tollfree && (
                            <>
                              <Styled.AddressParaText>
                                Toll Free:
                              </Styled.AddressParaText>
                              <Styled.LinkStyle2
                                className="popup-anchor"
                                href={`tel:${item.node.tollfree}`}
                                tabindex="0"
                              >
                                {item.node.tollfree}
                              </Styled.LinkStyle2>
                            </>
                          )}
                        </Styled.Td>
                        <Styled.Td>{item?.node?.branchHours}</Styled.Td>
                        <Styled.Td>
                          {item?.node?.offerings?.Services?.map(
                            (types, index) => {
                              return <div key={index}>{types}</div>;
                            }
                          )}
                        </Styled.Td>
                      </tr>
                    );
                  })}
              </Styled.TBody>
            </Styled.LocationTable>
          </Styled.TableSubContainer>
        </Row>
      </Styled.LocationContainer>
    </React.Fragment>
  );
};

export default LocationDefault;
